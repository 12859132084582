// Packages:
import React from 'react'
import styled from 'styled-components'


// Styles:
import { GENERAL_JSX, LinkHighlight } from '../styles'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6em;
`

const Content = styled.div`
  width: 100%;
  max-width: calc(50em - 1.5em);
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 30vh;

  @media (max-width: 666px) {
    width: auto;
    padding: 0 1.5em;
  }
`

const ContactInformation = styled(Section)``

const Title = styled.div`
  font-weight: 700;
  font-size: 3em;

  @media (max-width: 325px) {
    font-size: 2em;
  }

  @media (min-width: 325px) and (max-width: 400px) {
    font-size: 2.5em;
  }

  @media (min-width: 400px) and (max-width: 666px) {
    font-size: 3em;
  }
`

const SubTitle = styled.div`
  margin-top: 0.75em;
  font-weight: 400;
  font-size: 1em;

  @media (max-width: 325px) {
    font-size: 0.75em;
  }
`


// Functions:
const Contact = () => (
  <Wrapper>
    <Content>
      <ContactInformation>
        <Title>Contact</Title>
        <SubTitle>{ GENERAL_JSX.TWITTER } - Want to see what I'm upto? Drop me a tweet!</SubTitle>
        <SubTitle>{ GENERAL_JSX.EMAIL } - Want to discuss an idea or just chat? Feel free to drop me an email.</SubTitle>
        <SubTitle>{ GENERAL_JSX.GITHUB } - Check out more of my work. PRs and <LinkHighlight href='https://github.com/sponsors/diragb'>sponsorships</LinkHighlight> are appreciated!</SubTitle>
        <SubTitle>{ GENERAL_JSX.LINKEDIN } - Check out my portfolio.</SubTitle>
      </ContactInformation>
    </Content>
  </Wrapper>
)


// Exports:
export default Contact
